import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index.js";
import ElementUI from "element-ui";
import "../theme/index.css";
import "./assets/css/index.scss";
import request from "./request/index.js";
Vue.prototype.$axios = request;
function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
let partner_id = getQueryString("partner_id");
if (partner_id) {
  window.localStorage.setItem("partner_id", partner_id);
}
function isPc() {
  var userAgentInfo = navigator.userAgent;
  var agents = ["Windows", "Macintosh", "Linux"];
  for (var i = 0; i < agents.length; i++) {
    if (userAgentInfo.indexOf(agents[i]) > -1) {
      return true;
    }
  }
  return false;
}

if (!isPc()) {
  window.location = "http://web.niuniushouka.com";
}
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
