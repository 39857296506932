import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: window.localStorage.getItem("token")
      ? window.localStorage.getItem("token")
      : "",
    isLogin: window.localStorage.getItem("token") ? true : false,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
      // window.localStorage.token = token
    },
    setIsLogin: (state, isLogin) => {
      state.isLogin = isLogin;
    },
  },
  actions: {
    setUserToken({ commit }, params) {
      commit("setToken", params);
    },
    setIsLogin({ commit }, params) {
      commit("setIsLogin", params);
    },
  },

  getters: {
    getToken: (state) => {
      return state.token;
    },
    getisLogin: (state) => {
      return state.isLogin;
    },
  },
  modules: {},
});
