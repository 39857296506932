import Axios from "axios";
// import store from 'vuex'
import qs from "qs";
import router from "../router/index";
import store from "../store/index";
import Vue from "vue";
// import { Store } from "vuex";
var Vm = new Vue();

const request = Axios.create({
  baseURL: "https://api.niuniushouka.com",
  // baseURL: "https://testapi.niuniushouka.com",
  timeout: 30000, // 请求超时
  headers: {},
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么

    // 在发送请求之前做些什么
    // console.log(config)
    // console.log(store.state.token)

    if (store.state.token) {
      // 让每个请求携带令牌
      // ['X-Token']  自定义头键
      // 请根据实际情况修改
      config.headers["token"] = store.state.token;
    }
    config.headers["Content-Type"] =
      "application/x-www-form-urlencoded; charset=UTF-8";
    config.transformRequest = [
      function (data) {
        // 在请求之前对data传参进行格式转换
        data = qs.stringify(data);
        return data;
      },
    ];
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    let data = response.data;
    if (data.code == "996" || data.code == "995") {
      window.localStorage.clear("token");
      Vm.$message({
        type: "error",
        message: "请先登录后再使用",
      });
      store.dispatch("setIsLogin", false);
      router.push({
        path: "/memberCenter",
      });
    }
    return data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default request;
