<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    if (!window.localStorage.getItem('token')) {
      this.$store.state.isLogin = true
    }
  }
};
</script>

<style lang='scss'>
.app {
  background: #fff;
  min-width: 1080px;
}
</style>
