import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/index.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/cardRecycle",
        name: "cardRecycle",
        component: () => import("../views/cardRecycle/index.vue"),
        meta: {
          title: "卡券回收",
        },
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("../views/contactUs/index.vue"),
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "/memberCenter",
        name: "memberCenter",
        component: () => import("../views/memberCenter/index.vue"),
        redirect: "/memberCenter/userCenter",
        meta: {
          title: "会员中心",
        },
        children: [
          {
            path: "/memberCenter/userCenter",
            name: "userCenter",
            component: () =>
              import("../views/memberCenter/userCenter/index.vue"),
            meta: {
              title: "个人中心",
            },
          },
          {
            path: "/memberCenter/ZFBConfig",
            name: "ZFBConfig",
            component: () =>
              import("../views/memberCenter/ZFBConfig/index.vue"),
            meta: {
              title: "收款账号",
            },
          },
          {
            path: "/memberCenter/withdrawal",
            name: "withdrawal",
            component: () =>
              import("../views/memberCenter/withdrawal/index.vue"),
            meta: {
              title: "我要提现",
            },
          },
          {
            path: "/memberCenter/withdrawalRecord",
            name: "withdrawalRecord",
            component: () =>
              import("../views/memberCenter/record/withdrawalRecord.vue"),
            meta: {
              title: "提现记录",
            },
          },
          {
            path: "/memberCenter/paymentRecord",
            name: "paymentRecord",
            component: () =>
              import("../views/memberCenter/record/paymentRecord.vue"),
            meta: {
              title: "打款记录",
            },
          },
          {
            path: "/memberCenter/sellCardRecord",
            name: "sellCardRecord",
            component: () =>
              import("../views/memberCenter/record/sellCardRecord.vue"),
            meta: {
              title: "卖卡记录",
            },
          },
          {
            path: "/memberCenter/commonProblem",
            name: "commonProblem",
            component: () =>
              import("../views/memberCenter/commonProblem/index.vue"),
            meta: {
              title: "常见问题",
            },
          },
          {
            path: "/memberCenter/realName",
            name: "realName",
            component: () => import("../views/memberCenter/realName/index.vue"),
            meta: {
              title: "实名认证",
            },
          },
        ],
      },
      {
        path: "/announcement",
        name: "announcement",
        component: () => import("../views/announcement/index.vue"),
        meta: {
          title: "平台公告",
        },
      },
    ],
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/Agreement/index.vue"),
    meta: {
      title: "牛牛收卡网转让协议",
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");

    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

router.beforeEach((to, from, next) => {
  console.log(to, from);
  next();
  if (to.path == "/home") {
    if (window.localStorage.getItem("token")) {
      store.state.isLogin = true;
      next();
      return false;
    } else {
      let token = getQueryVariable("token");
      console.log("有token");
      if (token) {
        store.state.token = token;
        store.state.isLogin = true;
        window.localStorage.setItem("token", token);
        next();
        return false;
      }

      return false;
    }
  }
});
export default router;
